const sliders = () => {
    const $newsSlick = $('.news-container-slick');
    const newsBreakPoint = 992;
    const newsSlickSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        infinite: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: newsBreakPoint,
                settings: 'unslick'
            }
        ]
    };
    $newsSlick.slick(newsSlickSettings);

    //on resize re-initialization
    $(window).on('resize', function() {
        if ($(window).width() > newsBreakPoint) {
            if ($newsSlick.hasClass('slick-initialized')) {
                $newsSlick.slick('unslick');
            }
            return;
        }
        if (!$newsSlick.hasClass('slick-initialized')) {
            return $newsSlick.slick(newsSlickSettings);
        }
    });

    $('.slick.marquee').each(function () {
        const $marquee = $(this);

        const directionData = $marquee.attr('data-direction');
        const speedData = $marquee.attr('data-speed');
        let speed = 5000;
        if (speedData === 'slow') {
            speed = 7500;
        } else if (speedData === 'fast') {
            speed = 2500;
        }

        let marqueeSettings = {};

        if (directionData === 'rtl') {
            marqueeSettings = {
                speed: speed,
                autoplay: true,
                autoplaySpeed: 0,
                centerMode: true,
                cssEase: 'linear',
                variableWidth: true,
                infinite: true,
                arrows: false,
                buttons: false,
                pauseOnHover:false,
                rtl: true,
                slidesToScroll: -1,
                draggable: false,
                pauseOnFocus: false
            };
        } else {
            marqueeSettings = {
                speed: speed,
                autoplay: true,
                autoplaySpeed: 0,
                centerMode: true,
                cssEase: 'linear',
                variableWidth: true,
                infinite: true,
                arrows: false,
                buttons: false,
                pauseOnHover:false,
                draggable: false,
                pauseOnFocus: false
            };
        }

        $marquee.slick(marqueeSettings);
    });
};

export default sliders;