import isIE from './modules/isIE';
import isOnTouchDevice from './modules/isOnTouchDevice';
import mainNav from './modules/mainNav';
import smoothScroll from './modules/smoothScroll';
import sliders from './modules/sliders';
import videos from './modules/videos';
import videoSliders from './modules/videoSliders';
import accordions from './modules/accordions';
import tabs from './modules/tabs';
import contactForm from './modules/contactForm';
import socialMediaLink from './modules/socialMediaLink';
import dataLayer from './dataLayer';

$(function () {
	isIE();
	isOnTouchDevice();
	mainNav();
	smoothScroll();
	videos();
	sliders();
	videoSliders();
	accordions();
	tabs();
	contactForm();
	socialMediaLink();
	dataLayer();
});
