const dataLayer = () => {
    if (!window.dataLayer) return;

    // This is DL for external outbound link
    $('.js-dl-cta').on('click', function () {
        window.dataLayer.push({
            'event': $(this).data('link'),
        });
    });

    // This is DL for download patient booklet link in a paragraph
    $('.js-dl-download-patient-booklet').on('click', function () {
        window.dataLayer.push({
            'event': 'Download_patient_booklet',
            'download_section': $(this).data('section')
        });
    });

    // This is DL for download document link in a paragraph
    $('.js-dl-download-document').on('click', function () {
        window.dataLayer.push({
            'event': 'document_download',
            'file_name': $(this).attr('href')
        });
    });

    // This is DL for download patient booklet btn button in text-widget and image-widget components
    $('input.btn').on('click', function () {
        let linkText = $(this).val();
        const section = $(this).parents('section').attr('section-name');
        if($(this).val() == 'Download patient booklet') {
            linkText = 'Download_patient_booklet'
        }
        window.dataLayer.push({
            'event': linkText,
            'download_section': section
        });
    });

    // This is DL for download patient booklet btn link in large-text-widget and action-panel components
    $('a.btn, a.button').on('click', function () {
        let linkText = $(this).text();
        const section = $(this).parents('section').attr('section-name');
        if($(this).text() == 'Download patient booklet') {
            linkText = 'Download_patient_booklet'
        }
        window.dataLayer.push({
            'event': linkText,
            'download_section': section
        });
    });

    $('.refer-patient').on('click', function () {
        window.dataLayer.push({
            'event': 'refer_a_patient_click',
            'page_name': window.location.href,
            'program_name': 'sticky'
        });
    });

    $('.ctas a:contains("Refer a Patient"), .body-copy a:contains("Refer a Patient")').on('click', function () {
        const section = $(this).parents('section').attr('section-name');
        window.dataLayer.push({
            'event': 'refer_a_patient_click',
            'page_name': window.location.href,
            'program_name': section
        });
    });
};

export default dataLayer;