const mainNav = () => {
	const $submenuParents = $('.mega-menu-item.mega-menu-item-has-children.mega-menu-megamenu');

	$submenuParents.each(function( index ) {
		const $parent = $(this);
		$parent.append('<a class="mega-sub-menu-back" href="#">Back</a>');
		$(document).on( 'click', '.mega-sub-menu-back', function(e){
			e.preventDefault();
			$parent.removeClass('mega-toggle-on');
		});
	});

  // hide mobile submenu if visible when closing menu
  $(document).on( 'click', '.mega-menu-toggle:not(.mega-menu-open)', function(){
    $submenuParents.removeClass('mega-toggle-on');
  });

	$('.js-nav-trigger').click(function(){
		if ($(this).hasClass('nav-close')){
			$('.js-header').removeClass('open');
		} else {
			$(this).addClass('nav-open');
			$('.js-header').addClass('open');
		}
	});

	// prevent floating buttons from overlapping footer
	const observer = new IntersectionObserver(function (entries, obs) {
		entries.forEach(function (entry) {
			if (!entry.isIntersecting) {
				$('.header-sticky-cta').removeClass('scroll-end');
				$('#wpfront-scroll-top-container').removeClass('scroll-end');
			} else {
				$('.header-sticky-cta').addClass('scroll-end');
				$('#wpfront-scroll-top-container').addClass('scroll-end');
			}
		});
	}, {
		threshold: 1.0
	});

	observer.observe(document.querySelector('footer'));
};

export default mainNav;
