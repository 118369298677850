const tabs = () => {
  $(document).on('click','.tabs-nav > .nav-item', function(e){
      const selectedTab = $(this).data('tab'),
            tabContainers = $(this).parent('.tabs-nav').siblings().find('.tabs-content');

      $(this).parent('.tabs-nav').find('.nav-item').removeClass('active');
      $(this).addClass('active');
      tabContainers.hide();
      $(tabContainers).each(function () {
        if ($(this).data('content') == selectedTab) {
          $(this).show();
        }
      });
  });
};

export default tabs;