const videoSliders = () => {
    const slick = $('.slider-container').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        mobileFirst: true,
    });

    $('.slider-container').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        const targetiframe = slick.$slides[currentSlide];
        //unload and reload video on slide change so it stops playing video on the current slide
        const iframe = targetiframe.querySelector('iframe');
        if (iframe) {
            iframe.contentWindow.postMessage('{ "method": "pause" }', "https://player.vimeo.com");
        }
    });
    
    $('.slider-container').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        const targetiframe = slick.$slides[currentSlide];
        //unload and reload video on slide change so it stops playing video on the current slide
        const iframe = targetiframe.querySelector('iframe');
        if (iframe) {
            const player = new Vimeo.Player(iframe);
            const duration = player.getDuration();
            
            player.on('play', function() {
                window.dataLayer.push({
                    'event': 'vimeo_video_start',
                    'video_title': player.getVideoTitle(),
                    'video_progress': `${(player.getCurrentTime() / duration) * 100}%`
                });
              });
    
            player.on('ended', function() {
                console.log('video ended!')
                if (!window.dataLayer) return;
                window.dataLayer.push({
                    'event': 'vimeo_video_complete',
                    'video_title': player.getVideoTitle(),
                    'video_progress': `${(player.getCurrentTime() / duration) * 100}%`
                });
            });
        }
    });
    
    const videoSlide = $('.slide');
    [...videoSlide].forEach(slide => {
        const iframe = slide.querySelector('iframe');
        const player = new Vimeo.Player(iframe);
        const duration = player.getDuration();
        
        player.on('play', function() {
            window.dataLayer.push({
                'event': 'vimeo_video_start',
                'video_title': player.getVideoTitle(),
                'video_progress': `${(player.getCurrentTime() / duration) * 100}%`
            });
          });

        player.on('ended', function() {
            console.log('video ended!')
            if (!window.dataLayer) return;
            window.dataLayer.push({
                'event': 'vimeo_video_complete',
                'video_title': player.getVideoTitle(),
                'video_progress': `${(player.getCurrentTime() / duration) * 100}%`
            });
        });
    });

};

export default videoSliders;