const contactForm = () => {
  // const hideOnLoad = $('.contact-form .hide-on-load, .contact-form .evf-recaptcha-container, .contact-form .evf-submit-container');        
  // hideOnLoad.hide();
  
  // $('.contact-form .contact-tabs-parent input[type=radio]').on('change', function(){
  //   const selectedTab = $('.contact-form .contact-tabs-parent input[type=radio]:checked').val().replace(/\s+/g, '-').toLowerCase();
  //   $('.contact-form [class*="contact-tab-"]').hide();
  //   $('.contact-form [class*="contact-tab-"] input:checkbox').prop('checked', false);
  //   $('.contact-form .contact-tab-' + selectedTab).show();
  //   $('.contact-form .contact-form-field').show();
  //   hideOnLoad.show();
  // });

  // $($('.contact-form .contact-tabs-parent input[type=radio]')).each(function () {
  //   const tabName = this.value.replace(/\s+/g, '-').toLowerCase();

  //   if (window.location.href.indexOf('#' + tabName) > -1) {
  //     $(this).prop('checked', true).change();
  //   }
  // });
};

export default contactForm;