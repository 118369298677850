const accordions = () => {
    
    $(document).on('click', '.accordion-item > .accordion-title', function (e) {
        const contentGroup = $(this).parents('.accordion-grid').attr('section-name');
        $(this).next(".accordion-body").slideToggle('slow', function () {
            if ($(this).css('display') !== 'none') {
                if (!window.dataLayer) return;
                window.dataLayer.push({
                    'event': 'accordion_open',
                    'content_group ': contentGroup,
                    'question_label': $(this).siblings('.accordion-title').text()
                });
            } else {
                if (!window.dataLayer) return;
                window.dataLayer.push({
                    'event': 'accordion_close',
                    'content_group ': contentGroup,
                    'question_label': $(this).siblings('.accordion-title').text()
                });
            }
        });
        $(this).parent('.accordion-item').siblings().find('.accordion-body').slideUp();
    });
};

export default accordions;