const socialMediaLink = () => {
    const iconLink = $('.icon-link');
    const link = $('#linkCopy').attr('href');
    const tooltipParent = $('.tooltip')
    const tooltip = $('#myTooltip');

    iconLink.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (navigator.clipboard) { // incase we are not on HTTPS the navigator.clipboard won't be available
            navigator.clipboard.writeText(link);
        }
        tooltipParent.addClass('tooltip-active');
        tooltip.innerHTML = 'Link Copied';
        setTimeout(function () {
            tooltipParent.removeClass('tooltip-active');
        }, 1500);

        if (!window.dataLayer) return;
        window.dataLayer.push({
            'event': 'share_info_link_copy',
            'page_name': link
        });
    });

    const iconMail = $('.icon-mail');
    const iconMailLink = iconMail.parent().attr('href');

    iconMail.on('click', function (e) {
        if (!window.dataLayer) return;
        window.dataLayer.push({
            'event': 'share_info_email',
            'page_name': iconMailLink
        });
    });

    const iconWhatsapp = $('.icon-whatsapp');
    const iconWhatsappLink = iconWhatsapp.parent().attr('href');

    iconWhatsapp.on('click', function (e) {
        if (!window.dataLayer) return;
        window.dataLayer.push({
            'event': 'share_info_whatsapp',
            'page_name': iconWhatsappLink
        });
    });
};

export default socialMediaLink;