const videos = () => {
    $(document).on('click','.video-thumbnail', function(e){
        e.preventDefault();
        const $videoTrigger = $(this);
        const $videoIframe = $videoTrigger.siblings('.video-player-wrapper').find('iframe');
        const videoSource = $videoIframe.attr('src') + '&autoplay=1';
        $videoIframe.attr('src', videoSource);
        $videoTrigger.hide();
    });
};

export default videos;